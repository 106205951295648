import {
  PrimaryGeneratedColumn,
  UpdateDateColumn,
  CreateDateColumn,
  JoinColumn,
  ManyToOne,
  Column,
  Entity,
  DeleteDateColumn,
  OneToMany,
  Index,
} from 'typeorm';

import { GiveawayAudience } from '../models/giveaway-audience';
import { ProductType } from '../models/product';

import { InventoryProductImageUpload } from './FileUpload';
import { Product } from './Product';
import { User } from './User';

@Entity()
export class InventoryProduct {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  @Index('inventory_product_name_gin_idx', { synchronize: false })
  name!: string;

  @Column({ nullable: true, type: 'character varying' })
  @Index('inventory_product_description_gin_idx', { synchronize: false })
  description!: string | null;

  @Column({ type: 'enum', enum: ProductType })
  defaultType!: ProductType;

  @Column({ nullable: true, type: 'int4' })
  startingAmount!: number | null;

  @Column({ nullable: true, type: 'int4' })
  fixedAmount!: number | null;

  @Column()
  availableQuantity!: number;

  @Column({ nullable: true, type: 'character varying' })
  categoryName!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  brand!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  size!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  color!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  condition!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  model!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  gender!: string | null;

  @Column({ nullable: true, type: 'enum', enum: GiveawayAudience })
  giveawayAudience!: GiveawayAudience | null;

  @Column({ nullable: true, type: 'character varying' })
  cardCondition!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardLanguage!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardType!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardGradingService!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  cardGrade!: string | null;

  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Index()
  @Column()
  sellerId!: number;

  @OneToMany(() => Product, (product) => product.inventoryProduct)
  products!: Product[];

  @OneToMany(
    () => InventoryProductImageUpload,
    (upload) => upload.inventoryProduct,
  )
  @JoinColumn({ name: 'id', referencedColumnName: 'inventoryProductId' })
  images!: InventoryProductImageUpload[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date;

  // used to store a dynamic value equal to:
  // inventory product available quantity
  // +
  // sum of all related products' available quantity (from past shows)
  computedTotalQuantity?: number;
}

import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { Country, DEFAULT_COUNTRY_ID } from './Country';
import { Product } from './Product';
import { Show } from './Show';

export enum CommercialEventType {
  black_friday = 'black_friday',
  featured = 'featured',
  golden_days = 'golden_days',
  showtime_usa = 'showtime_usa',
  standard = 'standard',
  voggt_anniversary = 'voggt_anniversary',
}

export const TopLevelTierOneCommercialEventTypes = [
  CommercialEventType.black_friday,
  CommercialEventType.golden_days,
  CommercialEventType.showtime_usa,
  CommercialEventType.voggt_anniversary,
];

export const TierOneCommercialEventType = CommercialEventType.featured;

@Entity()
export class CommercialEvent {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  name!: string;

  @Column({ nullable: true, type: 'character varying', unique: true })
  internalName!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  description!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  iconUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  coverUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  bannerUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  largeCoverUrl!: string | null;

  @Column({ nullable: true, type: 'character varying' })
  largeBannerUrl!: string | null;

  @Column({ type: 'timestamp' })
  startAt!: Date;

  @Column({ nullable: true, type: 'timestamp' })
  realStartAt!: Date | null;

  @Column({ type: 'timestamp' })
  endAt!: Date;

  /**
   * @deprecated Will be replaced by countries
   */
  @ManyToOne(() => Country)
  @JoinColumn({ name: 'countryId' })
  country!: Country;

  /**
   * @deprecated Will be replaced by countries
   */
  @Column({ default: DEFAULT_COUNTRY_ID })
  countryId!: Country['id'];

  @ManyToMany(() => Country)
  @JoinTable({
    name: 'commercial_event_country',
  })
  countries!: Country[];

  @ManyToMany(() => Show)
  @JoinTable({
    name: 'commercial_event_show',
  })
  shows!: Show[];

  /**
   * TODO: remove this relationship
   * @deprecated we only use commercial events -> shows -> products
   */
  @ManyToMany(() => Product)
  @JoinTable({
    name: 'commercial_event_product',
  })
  products!: Product[];

  @CreateDateColumn()
  createdAt!: Date;

  @UpdateDateColumn()
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt!: Date;

  @Column({
    type: 'enum',
    enum: CommercialEventType,
    default: CommercialEventType.standard,
  })
  commercialEventType!: CommercialEventType;

  @Column({ default: 0 })
  weight!: number;

  @Column({ default: true })
  isVisibleForSellerStudio!: boolean;
}

import {
  ChildEntity,
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  PrimaryGeneratedColumn,
  TableInheritance,
} from 'typeorm';

import { Category } from './Category';
import { LoyaltyLevel } from './LoyaltyLevel';
import { User } from './User';

enum EmoteTypeDiscriminator {
  category = 'category',
  loyalty = 'loyalty',
  seller = 'seller',
  seller_loyalty = 'seller_loyalty',
}

@Entity()
@TableInheritance({
  column: {
    type: 'enum',
    name: 'type',
    enum: EmoteTypeDiscriminator,
  },
})
export class Emote {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Index()
  @Column({ unique: true })
  name!: string;

  @Column({ default: false })
  hasAnimatedVersion!: boolean;

  @CreateDateColumn()
  createdAt!: Date;

  @DeleteDateColumn()
  deletedAt!: Date;
}

@ChildEntity(EmoteTypeDiscriminator.category)
export class CategoryEmote extends Emote {
  @ManyToMany(() => Category, (category) => category.emotes)
  @JoinTable()
  categories!: Category[];

  /**
   * True = Everyone will see this emote.
   */
  @Column({ type: 'boolean', default: false })
  isGlobal!: boolean;
}

@ChildEntity(EmoteTypeDiscriminator.loyalty)
export class LoyaltyEmote extends Emote {
  @ManyToOne(() => LoyaltyLevel)
  @JoinColumn({ name: 'loyaltyLevelId' })
  loyaltyLevel!: LoyaltyLevel;

  @Column()
  loyaltyLevelId!: LoyaltyLevel['id'];
}

@ChildEntity(EmoteTypeDiscriminator.seller)
export class SellerEmote extends Emote {
  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Column()
  sellerId!: number;
}

@ChildEntity(EmoteTypeDiscriminator.seller_loyalty)
export class SellerLoyaltyEmote extends Emote {
  @ManyToOne(() => User)
  @JoinColumn({ name: 'sellerId' })
  seller!: User;

  @Column()
  sellerId!: number;

  @ManyToOne(() => LoyaltyLevel)
  @JoinColumn({ name: 'loyaltyLevelId' })
  loyaltyLevel!: LoyaltyLevel;

  @Column()
  loyaltyLevelId!: LoyaltyLevel['id'];
}

import { useEffect, useMemo } from 'react';

import {
  FieldDescriptor,
  OffenderType,
  SellerOffenseName,
  UserOffenseName,
} from '@bits-app/voggtpit-shared';
import { CircularProgress, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUserSanctions } from '../../../../hooks/useUserSanctions';
import { useUserStats } from '../../../../hooks/useUserStats';
import { SanctionForm } from '../const/SanctionForm';
import { useCreateSanctionForm, SanctionFormType } from '../hooks/use-create-sanction-form';
import { parseSuggestion } from '../utils/parse-suggestion';
import { getPreviousOffensesCount, getSuggestedSanction } from '../utils/utils';

type SanctionCreationFormProps = {
  userId: number;
  isOpen: boolean;
  onClose: () => void;
};

type SuggestionValues = {
  warning?: boolean;
  blockedAuctionUntil?: Date | null;
  blockedCommentUntil?: Date | null;
  blockedShowUntil?: Date | null;
  isHardblocked?: boolean;
};

export const SanctionCreationForm = ({ userId, isOpen, onClose }: SanctionCreationFormProps) => {
  const { t } = useTranslation();
  const { schema, onCreate, isLoadingSchema, methods } = useCreateSanctionForm(userId);

  const offenderType = methods.watch('offenderType');
  const offenseName = methods.watch('offenseName');

  const { data: userStats } = useUserStats(userId);
  const { data: sanctions } = useUserSanctions(userId);

  useEffect(() => {
    methods.setValue('warning', false);
    methods.setValue('blockedAuctionUntil', null);
    methods.setValue('blockedCommentUntil', null);
    methods.setValue('blockedShowUntil', null);
    methods.setValue('isHardblocked', false);
  }, [methods, offenderType, offenseName]);

  const suggestedSanction = useMemo(() => {
    if (!offenderType || !offenseName || !userStats || !sanctions) return null;

    const previousOffensesCount = getPreviousOffensesCount(sanctions, offenseName, offenderType);

    return getSuggestedSanction(
      offenseName,
      offenderType,
      userStats.buyerOrdersCount,
      userStats.sellerShowCount,
      previousOffensesCount,
    );
  }, [offenderType, offenseName, userStats, sanctions]);

  const updatedSchema = useMemo(() => {
    if (!schema || !Array.isArray(schema)) return [];

    return schema.map((category) => ({
      ...category,
      schema: category.schema
        .map((field: FieldDescriptor<SanctionFormType>) => {
          if (field.field === 'offenseName') {
            const offenseEnum =
              offenderType === OffenderType.buyer ? UserOffenseName : SellerOffenseName;

            return {
              ...field,
              options: Object.entries(offenseEnum).map(([key, value]) => ({
                value,
                label: t(`moderation.sanction.offense.${offenderType}.${key}`),
              })),
            };
          }
          if (field.field === 'blockedShowUntil') {
            return offenderType === OffenderType.seller ? field : null;
          }
          return field;
        })
        .filter(Boolean),
    }));
  }, [schema, offenderType, t]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFormValues = (methods: any, values: SuggestionValues) => {
    (Object.keys(values) as Array<keyof SuggestionValues>).forEach((key) => {
      if (values[key] !== undefined) {
        methods.setValue(key, values[key], {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    });
  };

  useEffect(() => {
    if (suggestedSanction) {
      const values = parseSuggestion(suggestedSanction);
      setFormValues(methods, values);
    }
  }, [methods, suggestedSanction]);

  if (isLoadingSchema) {
    return <CircularProgress />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('moderation.sanction.create.title')}</DialogTitle>
      <DialogContent>
        <SanctionForm
          methods={methods}
          schemas={updatedSchema}
          suggestedSanction={suggestedSanction}
          header={{ title: t('moderation.sanction.create.title') }}
          onUpsert={(data) => {
            onCreate(data);
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

import type { DegradedModeState } from '@bits-app/voggtpit-shared';
import { Checkbox, TextField } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type DegradedModeFormProps = {
  control: Control<DegradedModeState>;
  isReady: boolean;
};

export const DegradedModeForm = ({ control, isReady }: DegradedModeFormProps) => {
  if (!isReady) {
    return null;
  }

  return (
    <section className="flex flex-col shadow-sm w-full bg-white rounded-md border border-slate-200 gap-8">
      <div>
        <h3 className="font-bold text-2xl border-b border-b-slate-200 px-6 py-4">Seller Studio</h3>
        <div className="flex flex-col px-6 py-4 gap-4">
          <Controller
            name="sellerStudio.appIsEnabled"
            control={control}
            render={({ field }) => {
              return (
                <div className="flex flex-row gap-2 items-center">
                  <p>Shut down seller studio</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              );
            }}
          />

          <hr />

          <div className="flex flex-row gap-4">
            <Controller
              name="sellerStudio.alertIsEnabled"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Show alert</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-col lg:flex-row justify-between w-full gap-4">
            <Controller
              name="sellerStudio.title.fr"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="French title"
                  className="w-full"
                />
              )}
            />
            <Controller
              name="sellerStudio.title.en"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="English title"
                  className="w-full"
                />
              )}
            />
            <Controller
              name="sellerStudio.title.de"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="German title"
                  className="w-full"
                />
              )}
            />
          </div>

          <div className="flex flex-col lg:flex-row justify-between w-full gap-4">
            <Controller
              name="sellerStudio.alert.fr"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="French alert"
                  className="w-full"
                />
              )}
            />
            <Controller
              name="sellerStudio.alert.en"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="English alert"
                  className="w-full"
                />
              )}
            />
            <Controller
              name="sellerStudio.alert.de"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  label="German alert"
                  className="w-full"
                />
              )}
            />
          </div>

          <hr />

          <div className="flex flex-row gap-4 justify-between">
            <Controller
              name="sellerStudio.feature.auction"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Auction </p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
            <Controller
              name="sellerStudio.feature.flashSale"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Flash sale </p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
            <Controller
              name="sellerStudio.feature.giveaway"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Giveaway </p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
            <Controller
              name="sellerStudio.feature.shipment"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Shipment </p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-row gap-4">
            <Controller
              name="sellerStudio.feature.event"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap">Events (backend)</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
            <Controller
              name="sellerStudio.feature.log"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Send logs (backend)</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <h3 className="font-bold text-2xl border-b border-b-slate-200 px-6 py-4">Mobile app</h3>
        <div className="flex flex-col px-6 py-4 gap-4">
          <div className="flex flex-row gap-4">
            <Controller
              name="mobile.clientLogs"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Client logs</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                    disabled
                  />
                </div>
              )}
            />

            <Controller
              name="mobile.maintenanceMode"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-2 items-center flex-nowrap">
                  <p className="whitespace-nowrap"> Maintenance mode</p>
                  <Checkbox
                    onBlur={field.onBlur}
                    disabled
                    onChange={field.onChange}
                    checked={field.value}
                    inputRef={field.ref}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

import { useReducer } from 'react';

import { Shipment } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import _pick from 'lodash/pick';
import { useForm, type UseFormRegister, type Control } from 'react-hook-form';

import { authenticatedInstance } from '../../../../axios/axios.instance';
import { useSnackbar } from '../../../../components/elements/snackbar/use-snackbar';
import { useOwnUser } from '../../../../context/own-user.context';

type RefundAShipmentForm = Pick<
  Shipment,
  'easyShippingAmount' | 'externalShippingOrderId' | 'trackingNumber' | 'status' | 'labelPrintUrl'
>;

export type UseRefundAShipmentRegisterForm = UseFormRegister<RefundAShipmentForm>;
export type UseRefundAShipmentRegisterController = Control<RefundAShipmentForm, unknown>;

export const useRefundAShipment = (shipmentId: Shipment['id'], shipmentInformation: Shipment) => {
  const { register, handleSubmit, control } = useForm<RefundAShipmentForm>({
    defaultValues: _pick(shipmentInformation, [
      'easyShippingAmount',
      'externalShippingOrderId',
      'trackingNumber',
      'status',
      'labelPrintUrl',
    ]),
  });

  const [modalIsOpen, toggleModalIsOpen] = useReducer((state) => !state, false);
  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();
  const isAuthorized = hasPermissions(PERMISSIONS.REFUND.WRITE_REFUND);

  const { mutate, isLoading } = useMutation(
    async (form: RefundAShipmentForm) => {
      const response = await authenticatedInstance.post(
        `/database-explorer/shipment/${shipmentId}/refund`,
        form,
      );
      return response.data;
    },
    {
      onSuccess: () => {
        snackbar.success('shipment-refund.success');
        toggleModalIsOpen();
      },

      onError: (error: AxiosError) => {
        snackbar.error(error.message || 'shipment-refund.error');
      },
    },
  );

  const process = () => {
    if (!isAuthorized) {
      return;
    }
    handleSubmit((form) => {
      mutate(form);
    })();
  };

  return {
    isLoading,
    modalIsOpen,
    toggleModalIsOpen,
    process,
    isAuthorized,
    register,
    control,
  };
};

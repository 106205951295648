import {
  Column,
  CreateDateColumn,
  DeleteDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { BreakType } from '../models/break';

import { Product } from './Product';
import { Show } from './Show';

@Entity()
export class Break {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column()
  title!: string;

  @Column({ nullable: true, type: 'character varying' })
  description!: string | null;

  @Column({ type: 'enum', enum: BreakType })
  type!: BreakType;

  @OneToMany(() => Product, (product) => product.break)
  products!: Product[];

  @ManyToOne(() => Show, (show) => show.breaks)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  @Index()
  @Column()
  showId!: Show['id'];

  @CreateDateColumn()
  createdAt!: Date;

  @UpdateDateColumn()
  updatedAt!: Date;

  @DeleteDateColumn()
  deletedAt?: Date | null;
}

import { Button } from '@mui/material';

import { DegradedModeChangeSummary } from './components/DegradedModeChangeSummary';
import { DegradedModeForm } from './components/DegradedModeForm';
import { useQueryDegradedMode } from './hooks/useQueryDegradedMode';
import { useUpdateDegradedMode } from './hooks/useUpdateDegradedMode';

export const DegradedMode = () => {
  const { data, isLoading, refetch } = useQueryDegradedMode();
  const { control, mutate, watch } = useUpdateDegradedMode(data);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data available.</div>;
  }

  return (
    <div className="bg-slate-100 p-4 flex flex-col gap-4 max-w-4xl mx-auto">
      <section className="flex flex-col gap-4 p-4 bg-slate-300 opacity-95 rounded-sm border border-slate-400">
        <div className="flex flex-row justify-between">
          <h1 className="font-bold underline text-lg">Degraded Mode</h1>
          <Button
            onClick={() => refetch()}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Refresh
          </Button>
        </div>
        <p className="font-bold text-lg">
          This page allows you to disable some features and switch the app into Maintenance mode.
        </p>
      </section>

      <DegradedModeForm control={control} isReady={Boolean(!isLoading && data)} />

      <DegradedModeChangeSummary
        watch={watch}
        currentState={data}
        validateChange={() => mutate()}
      />
    </div>
  );
};

import {
  UserOffenseName,
  SellerOffenseName,
  OffenderType,
  SanctionType,
} from '@bits-app/voggtpit-shared';

import { createAction } from '../const/actions.const';
import {
  CONFIRMED_BUYER_DEF_BAN_OFFENSES,
  CONFIRMED_SELLER_DEF_BAN_OFFENSES,
  THRESHOLDS,
  YOUNG_BUYER_DEF_BAN_OFFENSES,
  YOUNG_SELLER_DEF_BAN_OFFENSES,
} from '../const/sanction.const';
import {
  youngBuyerSanctionMatrix,
  confirmedBuyerSanctionMatrix,
} from '../matrices/buyer-sanctions';
import {
  youngSellerSanctionMatrix,
  confirmedSellerSanctionMatrix,
} from '../matrices/seller-sanctions';
import { SanctionMatrix, SanctionLevel } from '../types/types';

export const isYoungBuyer = (buyerOrders: number): boolean => {
  return buyerOrders <= THRESHOLDS.YOUNG_USER_ORDERS;
};

export const isYoungSeller = (sellerShowCount: number): boolean => {
  return sellerShowCount < THRESHOLDS.YOUNG_SELLER_MIN_SHOWS;
};

const isBuyerOffense = (
  offense: UserOffenseName | SellerOffenseName,
): offense is UserOffenseName => {
  return Object.values(UserOffenseName).includes(offense as UserOffenseName);
};

const isSellerOffense = (
  offense: UserOffenseName | SellerOffenseName,
): offense is SellerOffenseName => {
  return Object.values(SellerOffenseName).includes(offense as SellerOffenseName);
};

export const getSanctionFromMatrix = <T extends string>(
  matrix: SanctionMatrix<T>,
  offense: T,
  previousOffensesCount: number,
): string => {
  const offenseMatrix = matrix[offense];
  if (!offenseMatrix) return 'Warning';

  const cappedOffenseCount = Math.min(Math.max(previousOffensesCount, 0), 2) as SanctionLevel;
  return offenseMatrix[cappedOffenseCount] || 'Hardblock';
};

/**
 * Determines if a user should be hardblocked based on their offense and status
 * @param offense - The type of offense committed
 * @param userType - Whether the user is a buyer or seller
 * @param isYoungUser - Whether the user is considered "young" based on activity
 * @returns boolean indicating if user should be hardblocked
 */
const shouldHardblock = (
  offense: UserOffenseName | SellerOffenseName,
  userType: OffenderType,
  isYoungUser: boolean,
): boolean => {
  if (userType === OffenderType.buyer && isBuyerOffense(offense)) {
    return isYoungUser
      ? YOUNG_BUYER_DEF_BAN_OFFENSES.includes(offense)
      : CONFIRMED_BUYER_DEF_BAN_OFFENSES.includes(offense);
  }
  if (userType === OffenderType.seller && isSellerOffense(offense)) {
    return isYoungUser
      ? YOUNG_SELLER_DEF_BAN_OFFENSES.includes(offense)
      : CONFIRMED_SELLER_DEF_BAN_OFFENSES.includes(offense);
  }

  return false;
};

/**
 * Gets the suggested sanction for a user based on their status and offense history
 * @param offenseName - The type of offense committed
 * @param userType - Whether the user is a buyer or seller
 * @param buyerOrdersCount - Number of orders as a buyer
 * @param sellerShowCount - Number of shows created as a seller
 * @param previousOffensesCount - Number of times this offense was committed before
 * @returns string describing the suggested sanction
 * @throws Error if invalid parameters are provided
 */
export const getSuggestedSanction = (
  offenseName: UserOffenseName | SellerOffenseName,
  userType: OffenderType,
  buyerOrdersCount: number,
  sellerShowCount: number,
  previousOffensesCount: number,
): string => {
  if (previousOffensesCount < 0) {
    throw new Error('Previous offenses count cannot be negative');
  }

  if (buyerOrdersCount < 0 || sellerShowCount < 0) {
    throw new Error('Counts cannot be negative');
  }

  const isBuyer = userType === OffenderType.buyer;
  const isYoungUser = isBuyer ? isYoungBuyer(buyerOrdersCount) : isYoungSeller(sellerShowCount);

  if (shouldHardblock(offenseName, userType, isYoungUser)) {
    return createAction.hardblock();
  }

  if (isBuyer) {
    const matrix = isYoungUser ? youngBuyerSanctionMatrix : confirmedBuyerSanctionMatrix;
    return getSanctionFromMatrix(matrix, offenseName as UserOffenseName, previousOffensesCount);
  } else {
    const matrix = isYoungUser ? youngSellerSanctionMatrix : confirmedSellerSanctionMatrix;
    return getSanctionFromMatrix(matrix, offenseName as SellerOffenseName, previousOffensesCount);
  }
};

export const getPreviousOffensesCount = (
  sanctions: SanctionType[],
  offenseName: UserOffenseName | SellerOffenseName,
  offenderType: OffenderType,
): number => {
  if (!sanctions) return 0;

  return sanctions.filter(
    (sanction) => sanction.offenseName === offenseName && sanction.offenderType === offenderType,
  ).length;
};

import type { DegradedModeState } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useQueryDegradedMode = () => {
  return useQuery(QUERIES_KEYS.getDegradedMode(), async () => {
    const { data } = await authenticatedInstance.get<DegradedModeState>('/degraded-mode');
    return data;
  });
};

import { useEffect } from 'react';

import type { DegradedModeState } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';

export const useUpdateDegradedMode = (initialData?: DegradedModeState) => {
  const defaultValues: DegradedModeState = {
    sellerStudio: {
      appIsEnabled: false,
      alertIsEnabled: false,
      alert: {
        fr: '',
        en: '',
        de: '',
      },
      title: {
        fr: '',
        en: '',
        de: '',
      },
      feature: {
        flashSale: false,
        giveaway: false,
        shipment: false,
        event: false,
        log: false,
        auction: false,
      },
      ...initialData?.sellerStudio,
    },
    mobile: {
      clientLogs: false,
      maintenanceMode: false,
      ...initialData?.mobile,
    },
  };

  const { getValues, control, watch, reset } = useForm<DegradedModeState>({
    defaultValues,
  });

  useEffect(() => {
    reset(initialData);
  }, [initialData, reset]);

  const snackbar = useSnackbar();
  const { mutate } = useMutation({
    mutationFn: () => {
      const payload = getValues();
      return authenticatedInstance.post('/degraded-mode', payload);
    },
    onError: (err) => {
      const error = typeof err === 'string' ? err : JSON.stringify(err);
      snackbar.error(`An error occurred: ${error}`);
    },
  });

  return {
    mutate,
    control,
    watch,
  };
};

import type { RGB } from '../models/color';

import {
  Column,
  CreateDateColumn,
  Entity,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { LoyaltyEmote, SellerLoyaltyEmote } from './Emote';

@Entity()
export class LoyaltyLevel {
  @PrimaryGeneratedColumn('uuid')
  id!: string;

  @Column({ unique: true })
  name!: string;

  @Column({ nullable: true, type: 'character varying' })
  description!: string | null;

  /**
   * Higher is better.
   */
  @Column()
  level!: number;

  @OneToMany(() => LoyaltyEmote, (emote) => emote.loyaltyLevel)
  loyaltyEmotes!: LoyaltyEmote[];

  @OneToMany(() => SellerLoyaltyEmote, (emote) => emote.loyaltyLevel)
  sellerLoyaltyEmotes!: SellerLoyaltyEmote[];

  @Column({ type: 'character varying' })
  imageFilename!: string;

  @Column()
  targetShowCount!: number;

  @Column({ type: 'jsonb' })
  colors!: RGB[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  // transient properties, only for GraphQL resolvers
  emptyLevel?: boolean;
}

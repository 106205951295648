import { DegradedModeState } from '@bits-app/voggtpit-shared';
import { Button } from '@mui/material';
import { UseFormWatch } from 'react-hook-form';

export const DegradedModeChangeSummary = ({
  watch,
  currentState,
  validateChange,
}: {
  watch: UseFormWatch<DegradedModeState>;
  currentState?: DegradedModeState;
  validateChange: () => void;
}) => {
  if (!currentState) {
    return null;
  }

  return (
    <section className="flex flex-col shadow-sm w-full bg-white rounded-md border border-slate-200">
      <h3 className="font-bold text-2xl border-b border-b-slate-200 px-6 py-4">Changes to apply</h3>
      <div className="flex flex-col px-6 py-4">
        <Change
          previousValue={currentState?.sellerStudio?.appIsEnabled}
          nextValue={watch('sellerStudio.appIsEnabled')}
          title={'Seller studio is enabled'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.title.fr || ''}
          nextValue={watch('sellerStudio.title.fr')}
          title={'Seller studio french title'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.title.de || ''}
          nextValue={watch('sellerStudio.title.de')}
          title={'Seller studio deutch title'}
        />
        <Change
          previousValue={currentState?.sellerStudio?.title.en || ''}
          nextValue={watch('sellerStudio.title.en')}
          title={'Seller studio english title'}
        />

        <Change
          previousValue={(currentState?.sellerStudio?.alertIsEnabled || false).toString()}
          nextValue={watch('sellerStudio.alertIsEnabled').toString()}
          title={'Alert is enabled'}
        />

        <Change
          previousValue={(currentState?.sellerStudio?.feature.auction || false).toString()}
          nextValue={watch('sellerStudio.feature.auction').toString()}
          title={'Auction is enabled'}
        />
        <Change
          previousValue={currentState?.sellerStudio?.alert?.fr || ''}
          nextValue={watch('sellerStudio.alert.fr')}
          title={'French alert'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.alert?.de || ''}
          nextValue={watch('sellerStudio.alert.de')}
          title={'German alert'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.alert?.en || ''}
          nextValue={watch('sellerStudio.alert.en')}
          title={'English alert'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.feature?.event}
          nextValue={watch('sellerStudio.feature.event')}
          title={'Feature event:'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.feature?.flashSale}
          nextValue={watch('sellerStudio.feature.flashSale')}
          title={'Feature flashsale:'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.feature?.giveaway}
          nextValue={watch('sellerStudio.feature.giveaway')}
          title={'Feature giveaway:'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.feature?.log}
          nextValue={watch('sellerStudio.feature.log')}
          title={'Feature logs:'}
        />

        <Change
          previousValue={currentState?.sellerStudio?.feature?.shipment}
          nextValue={watch('sellerStudio.feature.shipment')}
          title={'Feature shipment:'}
        />
        <Change
          previousValue={currentState?.mobile.clientLogs}
          nextValue={watch('mobile.clientLogs')}
          title={'Mobile - Client logs:'}
        />
        <Change
          previousValue={currentState?.mobile.maintenanceMode}
          nextValue={watch('mobile.maintenanceMode')}
          title={'Mobile - Maintenace mode:'}
        />
      </div>
      <div className="px-4 py-6 flex justify-end">
        <Button variant="contained" color="primary" onClick={validateChange}>
          Submit Change
        </Button>
      </div>
    </section>
  );
};

const Change = ({
  previousValue,
  nextValue,
  title,
}: {
  previousValue?: string | boolean;
  nextValue?: string | boolean;
  title: string;
}) => {
  if (previousValue === nextValue) {
    return null;
  }

  return (
    <div className="flex flex-row gap-6 py-2">
      <p>{title}:</p>

      <div className="px-6 flex flex-row gap-8">
        <span className="line-through text-red-500">
          {previousValue === ''
            ? 'undefined'
            : typeof previousValue === 'boolean'
              ? previousValue.toString()
              : previousValue}
        </span>
        👉
        <span>
          {nextValue === ''
            ? 'undefined'
            : typeof nextValue === 'boolean'
              ? nextValue.toString()
              : nextValue}
        </span>
      </div>
    </div>
  );
};

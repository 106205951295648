export enum BreakType {
  pick = 'pick', // users know what the spot contains
  // will be used next iteration
  // random = 'random', // randomization determines what the spot contains
}

export enum BreakDefaultSpotSellingMethod {
  auction = 'auction',
  instant_buy = 'instant_buy',
}

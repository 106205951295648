import { useEffect } from 'react';

import { GoogleServiceSignInResponse, useSSO } from '@bits-app/react-sso';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOwnUser } from '@/context/own-user.context';
import { useAppDispatch } from '@/redux/reduxAppHooks';

import { googleAuthentication } from '../use-cases';

export const Login = () => {
  const { ownUser } = useOwnUser();

  const navigate = useNavigate();
  const location = useLocation();
  const isVoggtLocation = location.pathname.includes('voggt');

  const { renderGoogleButton } = useSSO();
  const dispatch = useAppDispatch();

  useEffect(() => {
    renderGoogleButton(
      { disable_on_tap: true },
      { elementId: 'button-google-sign-in', size: 'large' },
      (response: GoogleServiceSignInResponse) => {
        dispatch(googleAuthentication(response.cred.credential, isVoggtLocation));
      },
    );
  }, [renderGoogleButton, dispatch, isVoggtLocation]);

  useEffect(() => {
    if (ownUser) {
      navigate(location?.state?.from ?? '/');
    }
  }, [location?.state?.from, navigate, ownUser]);

  const navigateToOtherSso = () => {
    const nextPath = isVoggtLocation ? '/login' : '/login-with-voggt';
    navigate(nextPath);
  };

  return (
    <div className="bg-slate-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <div className="w-full bg-white shadow rounded md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 md:space-y-6 sm:p-8 flex flex-col items-center">
            <h1 className="text-xl leading-tight tracking-tight text-gray-900">Welcome back</h1>

            <div className="flex flex-col gap-2">
              <div id="button-google-sign-in" />
              <p
                onClick={navigateToOtherSso}
                className="cursor-pointer font-light text-sm text-gray-600 text-center"
              >
                (Switch to {isVoggtLocation ? 'Fanatics' : 'Voggt'} SSO)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { SignInOwnUserDto } from '@bits-app/voggtpit-shared';

import { authenticatedInstance, publicInstance } from '@/axios/axios.instance';
import { OwnUser, ownUserDtoToEntity } from '@/domain/entities/own-user.entity';
import logger from '@/logger/logger';

import { AuthPort } from './auth.port';

export class AuthAdapter implements AuthPort {
  async loginWithGoogle(googleToken: string, isVoggtAuth: boolean): Promise<OwnUser> {
    try {
      const result = await publicInstance.post(
        isVoggtAuth ? '/auth/google-voggt' : '/auth/google',
        { token: googleToken },
      );

      // TODO rename api return with ownUser to avoid confusion. `user` reference voggt users
      return ownUserDtoToEntity(result.data.user);
    } catch (error) {
      throw new Error(`Something wrong happened: ${error}`);
    }
  }

  async loginWithPassword(credentials: { email: string; password: string }): Promise<OwnUser> {
    const result = await publicInstance.post('/auth/login', credentials);

    // TODO rename api return with ownUser to avoid confusion. `user` reference voggt users
    return ownUserDtoToEntity(result.data.user);
  }

  async signInWithPassword(credentials: SignInOwnUserDto): Promise<OwnUser> {
    const result = await publicInstance.post('/auth/sign-in', credentials);

    // TODO rename api return with ownUser to avoid confusion. `user` reference voggt users
    return ownUserDtoToEntity(result.data.user);
  }

  async changeOwnUserPassword({
    ownUserId,
    password,
  }: {
    password: string;
    ownUserId: OwnUser['id'];
  }): Promise<void> {
    await authenticatedInstance.patch(`/user/${ownUserId}/change-password`, { password });
  }

  async getMe() {
    try {
      // TODO rename api endpoint with own-user to avoid confusion. `user` reference voggt users
      const result = await authenticatedInstance.get('/user/me');

      return ownUserDtoToEntity(result.data);
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async logout() {
    try {
      await authenticatedInstance.get('/auth/logout');
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }

  async zendeskAuth(code: string) {
    try {
      await authenticatedInstance.post('/auth/zendesk', {
        code,
      });
    } catch (error) {
      logger.error(error);

      throw error;
    }
  }
}

export enum ProductType {
  auction = 'auction',
  giveaway = 'giveaway',
  instant_buy = 'instant_buy',
}

type FashionOrSneakersProductAttributes = {
  brand: string | null;
  color: string | null;
  condition: string | null;
  gender: string | null;
  model: string | null;
  size: string | null;
};

type TcgProductAttributes = {
  cardCondition: string | null;
  cardGrade: string | null;
  cardGradingService: string | null;
  cardLanguage: string | null;
  cardType: string | null;
};

export type ProductAttributes = FashionOrSneakersProductAttributes &
  TcgProductAttributes;

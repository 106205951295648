import { Column, Entity, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';

import { Show } from './Show';

@Entity()
export class ShowComputedData {
  @PrimaryColumn()
  showId!: number;

  @OneToOne(() => Show, (show) => show.computedData)
  @JoinColumn({ name: 'showId' })
  show!: Show;

  /**
   * Only used for data purposes, not used on backend at all
   */
  @Column({ nullable: true, type: 'int4' })
  presentUserCount!: number | null;
}

import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';

import { CategoryEmote } from './Emote';
import { ParentCategory } from './ParentCategory';
import { Show } from './Show';
import { UserFollowCategory } from './UserFollowCategory';

@Entity()
export class Category {
  @PrimaryGeneratedColumn('increment')
  id!: number;

  @Column()
  name!: string;

  @Column({ nullable: true })
  description!: string;

  @Column({ default: false })
  isVisible!: boolean;

  @Column({ default: false })
  isOffline!: boolean;

  @Column({ default: 0 })
  positionIndex!: number;

  /**
   * Actually a filename
   */
  @Column({ nullable: true, type: 'character varying' })
  coverUrl!: string | null;

  @Column({ nullable: true })
  colorAccent!: string;

  @Column({ default: false })
  isNew!: boolean;

  @ManyToOne(() => ParentCategory)
  @JoinColumn({ name: 'parentCategoryId' })
  parentCategory!: ParentCategory;

  @Index()
  @Column()
  parentCategoryId!: number;

  @OneToMany(() => Show, (livestream) => livestream.category)
  shows!: Show[];

  @CreateDateColumn({ name: 'createdAt', type: 'timestamp' })
  createdAt!: Date;

  @UpdateDateColumn({ name: 'updatedAt', type: 'timestamp' })
  updatedAt!: Date;

  @OneToMany(() => UserFollowCategory, (ufc) => ufc.category)
  userFollowCategory!: UserFollowCategory[];

  @ManyToMany(() => CategoryEmote, (emote) => emote.categories)
  emotes!: CategoryEmote[];
}

import { useReducer } from 'react';

import { User } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { useSnackbar } from '@/components/elements/snackbar/use-snackbar';
import { useOwnUser } from '@/context/own-user.context';

import { QUERIES_KEYS, queryClient } from '../../../queries';

export const useSsoRestoration = (user: User) => {
  const [ssoRestorationModalIsOpen, toggleRestorationModal] = useReducer((v) => !v, false);
  const snackbar = useSnackbar();
  const { hasPermissions } = useOwnUser();

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      authenticatedInstance.post(`/database-explorer/customer/${user.id}/restore-sso`),
    onSuccess: () => {
      snackbar.success('SSO restored successfully.');
      queryClient.invalidateQueries(QUERIES_KEYS.userDetail(user.id));
      toggleRestorationModal();
    },
    onError: (error: AxiosError) => {
      snackbar.error(error?.message || 'Failed to restore user SSO.');
    },
  });

  const shouldDisplaySsoRestoration = [
    hasPermissions(PERMISSIONS.USER.WRITE_USER_HARD_BLOCK),
    user,
    Boolean(user.userSso?.subject?.includes('deleted')),
  ].every(Boolean);

  return {
    ssoRestorationModalIsOpen,
    toggleRestorationModal,
    handleConfirm: () => {
      mutate();
    },
    shouldDisplaySsoRestoration,
    isLoading,
  };
};

import { useState } from 'react';

import { User } from '@bits-app/bits-server-data';
import { centsToUnit, formatAmount, PERMISSIONS, SanctionType } from '@bits-app/voggtpit-shared';
import { Button, Dialog } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';

import { Information, PanelDetails } from '../../../../../components/layouts/PanelDetails';
import { useOwnUser } from '../../../../../context/own-user.context';
import { ListWithExport } from '../../../../components/ListWithExport';
import { DetailsLayout } from '../../../../layouts/DetailsLayout';
import { useUserStats } from '../../../hooks/useUserStats';

import { CellSanctionDefinition } from './const/sanction.const';
import { panelDetailsModerationUserInfo } from './const/user-info-panel';
import { SanctionCreationForm } from './views/SanctionCreationForm';

type ModerationPanelDetails = {
  user: User;
};

type BuyerInfo = {
  buyerGmv: number;
  buyerOrdersCount: number;
};

export const ModerationPanel = ({ user }: ModerationPanelDetails) => {
  const { data: orderStats } = useUserStats(user.id);
  const [isSanctionDialogOpen, setIsSanctionDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const filteredInformation = panelDetailsModerationUserInfo.filter((info) => {
    if (sellerRelatedFields.includes(info.value)) {
      return user.isSeller;
    }
    return true;
  });

  const generalInfoItems = filteredInformation.filter(
    (info) =>
      ![
        'sellerCreatedAt',
        'sellerGmv',
        'sellerOrdersCount',
        'buyerGmv',
        'buyerOrdersCount',
        'sellerCanCreateShow',
      ].includes(info.value),
  );
  const sellerInfoItems = filteredInformation.filter((info) =>
    ['sellerCreatedAt', 'sellerGmv', 'sellerOrdersCount', 'sellerCanCreateShow'].includes(
      info.value,
    ),
  );

  const handleOpenSanctionDialog = () => setIsSanctionDialogOpen(true);
  const handleCloseSanctionDialog = () => setIsSanctionDialogOpen(false);

  return (
    <DetailsLayout>
      <Grid container spacing={1}>
        <Grid xs={12} justifyContent="flex-end" display={'flex'}>
          {hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_MODERATION) && (
            <Button variant="contained" color="primary" onClick={handleOpenSanctionDialog}>
              {t('moderation.sanction.create.title')}
            </Button>
          )}
        </Grid>
        <Grid xs={4}>
          <PanelDetails
            title={t('General info')}
            information={generalInfoItems}
            data={{
              ...user,
              buyerGmv: orderStats?.buyerGmv ?? 0,
              sellerGmv: orderStats?.sellerGmv ?? 0,
              buyerOrdersCount: orderStats?.buyerOrdersCount ?? 0,
              sellerOrdersCount: orderStats?.sellerOrdersCount ?? 0,
              sellerCanCreateShow: orderStats?.sellerCanCreateShow,
            }}
            labelRow
          />
        </Grid>
        <Grid xs={4}>
          <PanelDetails
            title={t('Buyer info')}
            information={buyerInfoItems}
            data={{
              buyerGmv: orderStats?.buyerGmv ?? 0,
              buyerOrdersCount: orderStats?.buyerOrdersCount ?? 0,
            }}
            labelRow
          />
        </Grid>
        <Grid xs={4}>
          <PanelDetails
            title={t('Seller info')}
            information={sellerInfoItems}
            data={{
              ...user,
              sellerCreatedAt: user.sellerCreatedAt,
              sellerGmv: orderStats?.sellerGmv ?? 0,
              sellerOrdersCount: orderStats?.sellerOrdersCount ?? 0,
              sellerCanCreateShow: orderStats?.sellerCanCreateShow,
            }}
            labelRow
          />
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid xs={12}>
          <ListWithExport<SanctionType>
            cellDefinition={CellSanctionDefinition}
            filters={{ userId: user.id }}
            dataName="sanctions"
          />
        </Grid>
      </Grid>

      <Dialog open={isSanctionDialogOpen} onClose={handleCloseSanctionDialog}>
        <SanctionCreationForm
          userId={user.id}
          isOpen={isSanctionDialogOpen}
          onClose={handleCloseSanctionDialog}
        />
      </Dialog>
    </DetailsLayout>
  );
};

const sellerRelatedFields = [
  'sellerCreatedAt',
  'sellerGmv',
  'sellerOrdersCount',
  'sellerCanCreateShow',
];

const buyerInfoItems: Information<BuyerInfo>[] = [
  {
    label: 'GMV Spent',
    value: 'buyerGmv',
    format: (data) =>
      data?.buyerGmv ? `${formatAmount(centsToUnit(Number(data.buyerGmv)))}€` : '-',
  },
  { label: 'Buyer Orders Count', value: 'buyerOrdersCount' },
];
